const resources = {
  en: {
    translation: {
      next: 'Next',
      remove: 'Remove',
      close: 'Close',
      edit: 'Edit',
      selectPlaceholder: 'Select an option',
      descriptionText: {
        expand: 'See more',
        shrink: 'See less',
      },
      cart: {
        add: 'Add to cart',
      },
      user: {
        profile: {
          title: 'Update your information',
        },
      },
      checkout: {
        contact: {
          title: 'How do we get in touch with you?',
        },
        delivery: {
          type: {
            title: 'Enter a shipping address',
          },
          method: {
            title: 'Choose a delivery method',
          },
        },
        confirm: {
          title: 'Confirm the order',
          subtitle: 'Check that all your data is in order',
        },
        result: {
          message: 'Your order has been generated successfully',
          subMessage: 'will contact you shortly.',
          thankYouMessage: '¡Thank you very much for preferring our products!',
        },
      },
      form: {
        name: 'Name',
        email: 'Email',
        phone: 'Phone',
      },
      createCatalogBanner: {
        title: 'Create a catalog like this in 5 minutes',
        callToActionText: 'Get started',
      },
    },
    gridSort: {
      newest: 'Newest',
      highlighted: 'Highlighted',
      'price-asc': 'Price: Low to High',
      'price-desc': 'Price: High to Low',
    },
  },
  es: {
    translation: {
      next: 'Siguiente',
      remove: 'Quitar',
      edit: 'Editar',
      close: 'Cerrar',
      goBack: 'Volver',
      signOut: 'Cerrar sesión',
      signIn: 'Ingresa',
      authMessage: 'Elige una opción para continuar',
      auth: 'Identificación',
      signInWithEmail: 'Ingresar',
      signInWithGoogle: 'Ingresar con Google',
      signUp: 'Registrate',
      signedInAs: 'Estás logueado como',
      selectPlaceholder: 'Selecciona una opción',
      emptyCategoryPlaceholder: 'Esta categoría no tiene productos',
      cart: {
        add: 'Agregar al carro',
        emptyPlaceholder: 'Tu carro esta vacio',
        disabledCheckoutMessage: '{{storeName}} desactivó los pedidos online',
        emptyButton: 'Vaciar carro',
      },
      catalog: {
        notFound: 'Catálogo no existe o está desactivado',
      },
      searchItemPlaceholder: 'Buscar un item...',
      descriptionText: {
        expand: 'Ver más',
        shrink: 'Ver menos',
      },
      user: {
        profile: {
          title: 'Actualiza tu información',
        },
      },
      checkout: {
        contact: {
          title: 'Como nos contactamos contigo',
          fields: {
            email: 'Email',
            phone: 'Telefono',
            name: 'Nombre',
          },
        },
        delivery: {
          type: {
            title: 'Ingresa una dirección de envío',
          },
          shippingAddress: {
            title: 'Cuál es tu dirección de envío',
            confirm: 'Confirmar dirección',
          },
          method: {
            title: 'Selecciona un metodo de envío',
          },
        },
        confirm: {
          title: 'Confirma el pedido',
          subtitle: 'Revisa que todos tus datos esten en orden',
        },
        result: {
          message: 'Tu pedido ha sido generado exitosamente',
          subMessage: 'se contactará contigo en breves momentos.',
          thankYouMessage: '¡Muchas gracias por preferir nuestros productos!',
        },
      },
      form: {
        name: 'Nombre',
        email: 'Email',
        phone: 'Telefono',
        password: 'Contraseña',
      },
      contact: 'Contacto',
      delivery: 'Entrega',
      payment: 'Pago',
      paymentMethods: {
        none: 'Coordinar con el vendedor',
        cash: 'Efectivo',
        transfer: 'Transferencia',
        onlinePayment: 'Pago en linea',
        flow: 'Pago en linea',
      },
      instructions: 'Instrucciones',
      confirm: 'Confirmación',
      confirmButton: 'Confirmar',
      deliveryTypes: {
        shipping: 'Envío',
        pickup: 'Retiro',
      },
      shippingAddress: {
        address: 'Dirección',
        city: 'Ciudad',
        state: 'Región',
        country: 'País',
      },
      orders: {
        status: {
          pending: 'Pendiente',
          confirmed: 'Confirmado',
          completed: 'Completado',
          canceled: 'Cancelado',
        },
      },
      userNavigation: {
        orders: 'Pedidos',
        profile: 'Perfil',
      },
      profile: {
        confirmationModal: {
          title: 'Listo!',
          subtitle: 'Tus datos han sido actualizados.',
        },
      },
      validationMessages: {
        required: 'Este campo es requerido',
        number: 'Este campo debe ser un numerico',
        email: 'Este campo debe contener un email',
        textOnly: 'Solo se permiten caracteres',
      },
      resetPassword: {
        description:
          'Ingresa tu dirección de email y te enviaremos un email con instrucciones para reestablecer tu contraseña',
        success: {
          title: 'Email de recuperación enviado',
          description:
            'Te enviamos un email con un link para reestablecer tu contraseña. Inicia sesión aquí con tu nueva contraseña para continuar. ',
        },
        button: {
          title: '¿Olvidaste tu contraseña?',
        },
        submit: {
          title: 'Enviar email de recuperación',
        },
      },
      changeButton: 'Cambiar',
      singInOrCreateButton: 'Ingresar o crear cuenta',
      signInWithDifferentMethod: 'Inicia con un metodo distinto',
      transfer: {
        button: 'Ver datos de transferencia',
        data: 'Datos de transferencia',
        accountDetails: 'Detalles de la cuenta',
      },
      createCatalogBanner: {
        title: 'Crea un catálogo como este en 5 minutos',
        callToActionText: 'Comenzar',
      },
      countries: {
        AR: 'Argentina',
        BO: 'Bolivia',
        CL: 'Chile',
        CO: 'Colombia',
        EC: 'Ecuador',
        ES: 'España',
        US: 'Estados Unidos',
        MX: 'México',
        PE: 'Perú',
        PY: 'Paraguay',
        UY: 'Uruguay',
        VE: 'Venezuela',
      },
      loading: 'Cargando...',
      gridSort: {
        newest: 'Nuevos',
        highlighted: 'Destacados',
        'price-asc': 'Precio: Bajo a Alto',
        'price-desc': 'Precio: Alto a Bajo',
      },
      outOfStock: 'Fuera de stock',
      continue: 'Continuar',
    },
  },
};

export default resources;
