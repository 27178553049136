import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  getAuth,
  signInWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signInAnonymously,
} from 'firebase/auth';
import { useModal } from '../Modal';
import SignIn from './SignInSimple';
import { getFirestore, doc, collection, setDoc } from 'firebase/firestore';
import Avatar from '../Avatar';
import { useAnalytics } from '../../providers/AnalyticsProvider';
import { LoadingDim } from '@posy/components';
import GoogleIcon from '../GoogleIcon';
import { MailIcon } from '@heroicons/react/outline';
import { UserIcon } from '@heroicons/react/outline';

const isWebview = require('is-ua-webview');

const Auth = () => {
  const [isCustomSignIn, setIsCustomSignIn] = useState(false);
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { logEvent } = useAnalytics();
  const [isLoading, setIsLoading] = useState(false);
  const isNotWebView = !isWebview(window.navigator.userAgent);

  const preSignIn = async (email) => {
    setIsLoading(true);
    const auth = getAuth();
    return fetchSignInMethodsForEmail(auth, email)
      .then((response) => {
        if (response.length === 0) {
          return { isRegistered: false };
        } else if (response[0] === 'password') {
          logEvent({ action: 'prelogin' });
          return { isRegistered: true };
        } else if (response[0] === 'google.com') {
          return { isInvalid: true, error: { code: 'google' } };
        }
      })
      .catch(function (error) {
        // Handle Errors here.
        const errorMessage = error.message;

        alert(errorMessage);

        return false;
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const signAsGuest = async () => {
    setIsLoading(true);
    const auth = getAuth();
    return signInAnonymously(auth)
      .then((userCredential) => {
        const user = userCredential.user;
        createUser(user.uid, { email: null, name: null, phone: null });
        logEvent({ action: 'login' });
        closeModal();

        return { isSuccess: true };
      })
      .catch(function (error) {
        setIsLoading(false);
        return { isSuccess: false, error };
      });
  };

  const signIn = async (formValues) => {
    setIsLoading(true);

    const auth = getAuth();
    const { email, password } = formValues;
    return signInWithEmailAndPassword(auth, email, password)
      .then(() => {
        logEvent({ action: 'login' });
        closeModal();

        return { isSuccess: true };
      })
      .catch(function (error) {
        setIsLoading(false);
        return { isSuccess: false, error };
      });
  };

  const createUser = (userId, userData) => {
    const db = getFirestore();
    const usersRef = collection(db, 'users');
    setDoc(doc(usersRef, userId), userData);
  };

  const SignWithGoogle = () => {
    setIsLoading(true);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        //const credential = GoogleAuthProvider.credentialFromResult(result);
        //const token = credential.accessToken;
        // The signed-in user info.
        const { user, _tokenResponse } = result;
        if (_tokenResponse.isNewUser) {
          logEvent({ action: 'sign_up' });
          createUser(user.uid, {
            email: user.email,
            name: user.displayName,
          });
        } else {
          logEvent({ action: 'login' });
        }

        closeModal();
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        //const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        //const email = error.email;
        // The AuthCredential type that was used.
        //const credential = GoogleAuthProvider.credentialFromError(error);
        alert(errorMessage);
        // ...
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const signUp = async (formValues) => {
    setIsLoading(true);
    const auth = getAuth();
    const { email, password } = formValues;

    return createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const { email, name, phone } = formValues;
        createUser(user.uid, { email, name, phone });
        logEvent({ action: 'sign_up' });
        closeModal();
      })
      .catch(function (error) {
        setIsLoading(false);

        return { isSuccess: false, error };
      });
  };

  const resetPassword = (email) => {
    setIsLoading(true);
    const auth = getAuth();

    return sendPasswordResetEmail(auth, email).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <div className={`mx-auto w-full max-w-sm lg:w-96`}>
      <div className="text-center mb-8">
        <Avatar />
        <h5 className="mt-2 text-2xl font-extrabold text-gray-900">
          {t('authMessage')}
        </h5>
      </div>

      {isCustomSignIn ? (
        <SignIn
          preSignIn={preSignIn}
          signIn={signIn}
          signUp={signUp}
          goBack={() => setIsCustomSignIn(false)}
          resetPassword={resetPassword}
        />
      ) : (
        <div className="flex flex-col justify-center m-4">
          {isNotWebView && (
            <Button
              label="Ingresa con Google"
              Icon={GoogleIcon}
              onPress={SignWithGoogle}
            />
          )}

          <Button
            label="Ingresa con Email"
            Icon={MailIcon}
            onPress={() => setIsCustomSignIn(true)}
          />
          <Button
            label="Ingresa como invitado"
            Icon={UserIcon}
            onPress={signAsGuest}
          />
        </div>
      )}
      <LoadingDim isLoading={isLoading} />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const Button = ({ label, Icon, onPress }) => {
  return (
    <button
      className="bg-gray-100 px-6 py-3 shadow-md rounded-md text-gray-600 flex justify-center m-2"
      onClick={onPress}
    >
      <Icon className="w-6 h-6" />
      <span className="ml-4">{label}</span>
    </button>
  );
};
export default Auth;
